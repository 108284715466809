// HighlightedContent.js
import React from "react";
import { useScroll } from "../../../../context/scroll-context"; // Import the scroll context

const HighlightedContent = () => {
    const { scrollToSection, contactform } = useScroll(); // Get the scroll context

    return (
        <>
            Launch your online store in 4 weeks,{" "}
            <span
                onClick={() => scrollToSection(contactform)}
                className="text-decoration-underline cursor-pointer"
            >
                get started today
            </span>
            .
        </>
    );
};

export default HighlightedContent;

import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { useScroll } from "../../../../context/scroll-context";
import ProductBrandsLogos from '../../../../images/solutions/hero-brands-logo.webp'

const SolutionsHero = ({ title, backgroundImage, content, highlightedContent, pageName }) => {
    const { scrollToSection, contactform } = useScroll();

    const handleScrollAndClose = (refName) => {
        scrollToSection(refName);
    };

    return (
        <section className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Container fluid className="px-4 py-4 py-lg-5">
                <Row>
                    <Col xs="12" lg="7">
                        <div className="content-block mb-3 text-start">
                            <h1 className="text-white text-capitalize ft-wt-600 mb-4 big-title">{title}</h1>
                            <h3 className="text-white mb-4 text-capitalize fw-normal">{content}</h3>
                            <h1 className="text-white text-capitalize ft-wt-600 mb-5 big-title d-inline-block d-lg-none">{highlightedContent}</h1>
                            {pageName === "ecommerce" && (
                                <div className="hero-brands-block d-inline-block d-lg-none mb-5">
                                    <img src={ProductBrandsLogos} alt="brand-logos" loading="lazy" className="img-fluid" />
                                </div>
                            )}
                            <div>
                                <Button className="text-uppercase" variant="secondary" onClick={() => handleScrollAndClose(contactform)}>
                                    <h5 className="mb-0 text-primary">Apply for free 2 weeks trial</h5>
                                </Button>
                            </div>
                        </div>
                    </Col>

                    {/* Conditionally render hero-brands-block only if the pageName is 'ecommerce' */}
                    <Col xs="12" lg="5" className="d-none d-lg-inline-block">
                        <h1 className="text-white text-capitalize ft-wt-600 mb-5 highlight-title text-end">{highlightedContent}</h1>
                        {pageName === "ecommerce" && (
                            <div className="hero-brands-block">
                                <img src={ProductBrandsLogos} alt="brand-logos" loading="lazy" className="img-fluid" />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default SolutionsHero;
